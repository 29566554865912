.progress-bar__stack {
  flex-grow: 1;
  display: flex;
  width: 100%;
  background: $white;
  border-radius: 16px;
  padding: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.progress-bar__stack__tooltip {
  margin-top: -50px;
  visibility: hidden;
  width: 120px;
  background-color: $white;
  color: $black;
  text-align: center;
  border-radius: 6px;
  padding: 6px 0;
  position: absolute;
  // it is over 48 as must be visible over all the possible half hours in a day
  z-index: 49;
  // hack so drag and drop works on iOS safari
  transform: rotate(0deg);
  -webkit-transform: translate3d(0, 0, 0);
}

.stack-unit:hover .progress-bar__stack__tooltip {
  visibility: visible;
}

.stack-unit--first:hover .progress-bar__stack__tooltip {
  visibility: visible;
}

.stack-unit {
  height: 40px;
  border-radius: 0px 16px 16px 0px;
  margin-left: -16px;
}

.stack-unit--first {
  height: 40px;
  border-radius: 16px 16px 16px 16px;
}

/* Mobile fucntionality */
@include mobileOnly() {
  .stack-unit {
    height: 20px;
    border-radius: 6px;
  }

  .stack-unit--first {
    height: 20px;
    border-radius: 6px;
  }

  .progress-bar__stack {
    border-radius: 6px;
  }
}
