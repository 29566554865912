.c-time-drawer {
  position: fixed;
  bottom: 0;
  padding-bottom: 28px; //this must be 28 or higher to stop the back/home/apps buttons bar appearing and cancelling the drag event
  padding-top: 8px;

  overflow: hidden; /* Hide scrollbars */

  &--landing {
    overflow: hidden; /* Hide scrollbars */
  }

  &__shape {
    display: flex;
    justify-content: space-between;
  }

  &__CTA {
    padding: 20px 8px;
  }
}

/* Mobile functionality */

@include mobileOnly() {
  .c-time-drawer__shape {
    width: auto;
    padding: 0px 8px;
  }
}
