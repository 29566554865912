/* Background Colours */
.u-blue-background {
  background-color: $everyHourBlue;
}
.u-white-background {
  background-color: $white;
}

.u-translucent-black-background {
  background-color: $translucent_black;
}

.u-very-translucent-black-background {
  background-color: $very_translucent_black;
}

.u-fade-background--left--white {
  background-image: linear-gradient(to right, #ffffff30, #00000000);
}

.u-fade-background--right--white {
  background-image: linear-gradient(to right, #00000000, #ffffff30);
}

/* Borders */
.u-bb--light-grey {
  border-bottom: 2px solid $white;
}

.u-b--light-grey {
  border: 1px solid $light_grey;
}

/* Sizing */

.u-w--15pct {
  width: 15%;
}

.u-w--25pct {
  width: 25%;
}

.u-w--33pct {
  width: 33%;
}

.u-w-half {
  width: 50%;
}

.u-w--75pct {
  width: 75%;
}

.u-w-auto {
  width: auto;
}

.u-w-all {
  width: 100%;
}

.u-w-fit {
  width: fit-content;
}

.u-h-all {
  height: 100%;
}

.u-h--75pct {
  height: 75%;
}

.u-h--50pct {
  height: 50%;
}

.u-vh {
  height: 100vh;
}

.u-vw {
  width: 100vw;
}

.u-fill-available {
  width: -webkit-fill-available;
  width: -moz-available;
}

.u-fill-available--height {
  height: -webkit-fill-available;
  height: -moz-available;
}

.u-scale--150 {
  transform: scale(1.5);
}

/* Positioning */

.u-show {
  display: initial;
}

.u-hide {
  display: none;
}

.u-overflow-scroll {
  overflow: scroll;
}

.u-overflow-x--hidden {
  overflow-x: hidden;
}

.u-overflow--hidden {
  overflow: hidden;
}

.u-overflow--auto {
  overflow: auto;
}

.u-inline {
  display: inline;
}

.u-block {
  display: block;
}

.u-flex {
  display: flex;
}

.u-inline-flex {
  display: inline-flex;
}

.u-z-index---1 {
  z-index: -1;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex--row-reverse {
  flex-direction: row-reverse;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-hold {
  flex-shrink: 0;
}

.u-flex-grow {
  flex-grow: 1;
}

.u-flex-wrap {
  flex-flow: wrap;
}

.u-align-center {
  align-items: center;
}

.u-align-baseline {
  align-items: baseline;
}

.u-justify-between {
  justify-content: space-between;
}

.u-justify-end {
  justify-content: end;
}
.u-justify-flex-end {
  justify-content: flex-end;
}

.u-justify-center {
  justify-content: center;
}

.u-wrap {
  max-width: 1280px;
  width: 100%;
  padding: 0px 20px;
  margin: 0 auto;

  .u-wrap {
    padding: 0px;
  }
}

.u-wrap--landing {
  @extend .u-wrap;
  max-width: 1080px;
}

.u-relative {
  position: relative;
}

.u-absolute {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-top--0 {
  top: 0;
}

.u-left--0 {
  left: 0;
}

.u-right--0 {
  right: 0;
}

.u-bottom--0 {
  bottom: 0;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-object-fit--fill {
  width: inherit;
  object-fit: contain;
}

/* Justify */

.u-justify-between {
  justify-content: space-between;
}

.u-justify-end {
  justify-content: end;
}

.u-justify-center {
  justify-content: center;
}

/* Other */
.u-round {
  border-radius: 50%;
}

.u-slim-border {
  border-radius: 20px;
  border: 1px solid #fff;

  &--grey {
    @extend .u-slim-border;
    border: 1px solid grey;
  }
}

.u-border-radius--small {
  border-radius: $smallBorderRadius;
}

.u-opacity--0 {
  opacity: 0;
}

.u-opacity--1 {
  opacity: 1;
}

.u-vis--hidden {
  visibility: hidden;
}

.u-grid--3 {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

/* Mobile functionality */

@include mobileOnly() {
  .u-wrap {
    padding: 0px 12px;
  }

  .u-hide--mobile {
    display: none;
  }
  .u-w-all--mobile {
    width: 100%;
  }

  .u-flex-column--mobile {
    flex-direction: column;
  }

  .u-grid--1--mobile {
    display: grid;
    grid-template-columns: 100%;
  }

  .u-grid--2--mobile {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .u-vw--90--mobile {
    width: 90vw;
  }

  .u-block--mobile {
    display: block;
  }
}

@include tabletAndDesktopOnly() {
  .u-w-half--desktop {
    width: 50%;
  }

  .u-hide--tabletAndDesktop {
    display: none;
  }

  .u-flex--desktop {
    display: flex;
  }
}
