$potMenuButtonWidth: 48px;

.pots-container {
  overflow: scroll;

  &--landing-page {
    overflow-x: hidden;
  }

  &--onboarding {
    @extend .pots-container;
    padding-top: 16px;
  }
}

.pots-container__grid {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  &--3 {
    @extend .pots-container__grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &--mobile {
    @extend .pots-container__grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}

.pot {
  border-radius: $largeBorderRadius;
  height: $potHeight;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  &--taro {
    @extend .pot;
    background-color: $taro;
  }
  &--grass {
    @extend .pot;
    background-color: $grass;
  }
  &--mustard {
    @extend .pot;
    background-color: $mustard;
  }
  &--tomato {
    @extend .pot;
    background-color: $tomato;
  }
  &--thin {
    @extend .pot;
    border-radius: $mediumBorderRadius;
    height: 75px;
  }
  &--mobile {
    @extend .pot;
    height: $mobilePotHeight;
    border-radius: $mediumBorderRadius;
  }
}

.testimonial-pot {
  @extend .pot;
  display: block;
  padding: 12px;
  height: fit-content;

  &--taro {
    @extend .testimonial-pot;
    background-color: $taro;
  }
  &--grass {
    @extend .testimonial-pot;
    background-color: $grass;
  }
  &--mustard {
    @extend .testimonial-pot;
    background-color: $mustard;
  }
  &--tomato {
    @extend .testimonial-pot;
    background-color: $tomato;
  }
}

.pot-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.pot__menu {
  width: $potMenuButtonWidth;
  color: $white;

  &:hover {
    color: $light_grey;
  }
}

.pot__icon {
  right: 0;
  width: 75%;
  height: auto;
  position: absolute;
  margin-bottom: -32px;
  opacity: 0.2;
  bottom: 0;
  margin-right: -32px;
  transform: rotate(20deg);
}

/* Mobile functionality */

@include mobileOnly() {
  .pots-container__grid {
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  .pot {
    height: $mobilePotHeight;
    border-radius: $mediumBorderRadius;
  }

  .testimonial-pot {
    height: fit-content;
  }
}
