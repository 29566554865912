.o-settings__drop-down {
  display: none;
  position: absolute;
  max-width: 180px;
  border-radius: $smallBorderRadius;
  background-color: $almost_white;
  box-shadow: 0px 8px 16px 0px $translucent_black;
  z-index: 1;
  // hack so drag and drop works on iOS safari
  transform: rotate(0deg);
  -webkit-transform: translate3d(0, 0, 0);
  overflow: hidden;
  :first-child {
    border-bottom: $light_grey solid 1px;
  }
}

.o-settings__drop-down-link {
  font-size: 1rem;
  padding: 12px;
  text-align: left;
  width: 100%;
}

.o-settings__drop-down-link:hover {
  background-color: $light_grey;
}

.o-settings:hover .o-settings__drop-down {
  display: grid;
}
