.u-fade-out {
  opacity: 0;
  transition: 300ms opacity;
}

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
    transform: rotateX(-360deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
    transform: rotateX(-360deg);
  }
}

.flip-horizontal-bottom {
  -webkit-animation: flip-horizontal-bottom 0.2s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-horizontal-bottom 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 1s infinite linear;
}

// used on the landing pages sign up button
@-webkit-keyframes change-background-color {
  0% {
    background-color: $everyHourBlue;
  }
  50% {
    background-color: black;
  }
  100% {
    background-color: $everyHourBlue;
  }
}

// .change-background-color {
//   animation: change-background-color 0.2s infinite;
// }

.change-background-color {
  -webkit-animation: change-background-color 1s;
  animation: toggle-background-black 1s;
  animation-iteration-count: 2;
}

@keyframes toggle-background-black {
  0% {
    background-color: $everyHourBlue;
  }
  50% {
    background-color: black;
  }
  100% {
    background-color: $everyHourBlue;
  }
}
