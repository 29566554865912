$xxSmallFontSize: 12px;
$xSmallFontSize: 16px;
$smallFontSize: 24px;
$mediumFontSize: 32px;
$largeFontSize: 48px;
$xLargeFontSize: 64px;
$xxLargeFontSize: 75px;

$lineHeightMultiplier: 1.5;

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: $white;
  color: $black;
  font: $font;
}

.u-bold {
  font-weight: 600;
}

.u-text-shadow {
  text-shadow: 1px 1px 4px rgb(31, 31, 31);
}

.u-color-red {
  color: $red;
}

.u-color-white {
  color: $white;
}

.u-color-black {
  color: $black;
}

.u-color-red {
  color: $red;
}

.u-color-grey {
  color: $grey;
}

.u-color-light-grey {
  color: $light_grey;
}

.u-color-black {
  color: $black;
}

.u-text-stroke--black {
  -webkit-text-stroke: 1px black;
}

.u-link-white {
  color: $white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.u-font--input {
  font-size: 22px !important;
}

.u-link--active {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.u-text-oneline {
  white-space: nowrap;
}

.u-text-align-center {
  text-align: center;
}

.u-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;

  .u-no-truncate {
    white-space: pre-wrap;
  }

  &--2l,
  &--3l,
  &--4l,
  &--5l {
    white-space: pre-line;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 100%;
    -webkit-line-clamp: 2;
  }

  &--3l {
    -webkit-line-clamp: 3;
  }

  &--4l {
    -webkit-line-clamp: 4;
  }

  &--5l {
    -webkit-line-clamp: 5;
  }
}

.u-input-error-text {
  color: $red;
  font-size: 12px;
  text-align: left;
  font-family: "Lato", sans-serif;
}

.u-main-font {
  font-family: "Patua One", sans-serif;
}

.u-main-font--vsmall {
  font-family: "Patua One", sans-serif;
  font-size: $xSmallFontSize;
  line-height: $lineHeightMultiplier * $xSmallFontSize;
}

.u-main-font--small {
  font-family: "Patua One", sans-serif;
  font-size: $smallFontSize;
  line-height: $lineHeightMultiplier * $smallFontSize;
}

.u-main-font--medium {
  font-family: "Patua One", sans-serif;
  font-size: $mediumFontSize;
  line-height: $lineHeightMultiplier * $mediumFontSize;
}

.u-main-font--large {
  font-family: "Patua One", sans-serif;
  font-size: $largeFontSize;
  line-height: $lineHeightMultiplier * $largeFontSize;
}

.u-main-font--vlarge {
  font-family: "Patua One", sans-serif;
  font-size: $xLargeFontSize;
  line-height: $lineHeightMultiplier * $xLargeFontSize;
}

.u-main-font--vvlarge {
  font-family: "Patua One", sans-serif;
  font-size: $xxLargeFontSize;
  line-height: $xxLargeFontSize;
}

.u-sub-font {
  font-family: "Lato", sans-serif;
}

.u-sub-font--vvsmall {
  font-family: "Lato", sans-serif;
  font-size: $xxSmallFontSize;
  line-height: $lineHeightMultiplier * $xxSmallFontSize;
}

.u-sub-font--vsmall {
  font-family: "Lato", sans-serif;
  font-size: $xSmallFontSize;
  line-height: $lineHeightMultiplier * $xSmallFontSize;
}

.u-sub-font--small {
  font-family: "Lato", sans-serif;
  font-size: $smallFontSize;
  line-height: $lineHeightMultiplier * $smallFontSize;
}

.u-sub-font--medium {
  font-family: "Lato", sans-serif;
  font-size: $mediumFontSize;
  line-height: $lineHeightMultiplier * $mediumFontSize;
}

.u-sub-font--large {
  font-family: "Lato", sans-serif;
  font-size: $largeFontSize;
  line-height: $lineHeightMultiplier * $largeFontSize;
}

.u-sub-font--vlarge {
  font-family: "Lato", sans-serif;
  font-size: $xLargeFontSize;
  line-height: $lineHeightMultiplier * $xLargeFontSize;
}

.u-sub-font--vvlarge {
  font-family: "Lato", sans-serif;
  font-size: $xxLargeFontSize;
  line-height: $xxLargeFontSize;
}

.u-underline--everyhour-style {
  text-decoration: none;
  padding-bottom: 4px;
  background: url(./underline_image.png) bottom repeat-x;
  background-size: 100%;
  &--inline {
    @extend .u-underline--everyhour-style;
    margin: 0px 6px 2px 0px;
  }
}

.u-underline {
  padding-bottom: 12px;
  position: relative;
  text-decoration: underline;
  &--green {
    @extend .u-underline;
    text-decoration: none;
  }
  &--yellow {
    @extend .u-underline;
    text-decoration: none;
  }
  &--orange {
    @extend .u-underline;
    text-decoration: none;
  }
}

.u-underline--green::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $grass;
  height: 10px;
  border-radius: 5px;
}
.u-underline--yellow::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $mustard;
  height: 10px;
  border-radius: 5px;
}
.u-underline--orange::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $tomato;
  height: 10px;
  border-radius: 5px;
}

.u-italic {
  font-style: italic;
}

.u-text-unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

@include mobileOnly() {
  //all mobile sizes should be the step smaller than normal
  .u-font--input {
    font-size: $xSmallFontSize !important;
  }
  //main font
  .u-main-font--vsmall {
    font-size: $xSmallFontSize;
    line-height: $lineHeightMultiplier * $xSmallFontSize;
  }

  .u-main-font--small {
    font-size: $xSmallFontSize;
    line-height: $lineHeightMultiplier * $xSmallFontSize;
  }

  .u-main-font--medium {
    font-size: $smallFontSize;
    line-height: $lineHeightMultiplier * $smallFontSize;
  }

  .u-main-font--large {
    font-size: $mediumFontSize;
    line-height: $lineHeightMultiplier * $mediumFontSize;
  }

  .u-main-font--vlarge {
    font-size: $largeFontSize;
    line-height: $lineHeightMultiplier * $largeFontSize;
  }

  .u-main-font--vvlarge {
    font-size: $xLargeFontSize;
    line-height: $lineHeightMultiplier * $xLargeFontSize;
  }

  //sub font
  .u-sub-font--vsmall {
    font-size: $xxSmallFontSize;
    line-height: $lineHeightMultiplier * $xxSmallFontSize;
  }

  .u-sub-font--small {
    font-size: $xSmallFontSize;
    line-height: $lineHeightMultiplier * $xSmallFontSize;
  }

  .u-sub-font--medium {
    font-size: $smallFontSize;
    line-height: $lineHeightMultiplier * $smallFontSize;
  }

  .u-sub-font--large {
    font-size: $mediumFontSize;
    line-height: $lineHeightMultiplier * $mediumFontSize;
  }

  .u-sub-font--vlarge {
    font-size: $largeFontSize;
    line-height: $lineHeightMultiplier * $largeFontSize;
  }

  .u-sub-font--vvlarge {
    font-size: $xLargeFontSize;
    line-height: $lineHeightMultiplier * $xLargeFontSize;
  }
}
