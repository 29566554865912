.backdrop-style {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $very_translucent_black;
  padding: 50px;
  max-width: unset;
  //must be larger than all possible days and the tooltips hence 50
  z-index: 50;
  // hack so drag and drop works on iOS safari
  transform: rotate(0deg);
  -webkit-transform: translate3d(0, 0, 0);
}

.add-new-pot__modal {
  background-color: $white;
  width: auto;
  margin: 0 auto;
  border-radius: 8px;
}

.colourPicker {
  width: 80px;
  height: 40px;
}
