.o-background-image {
  position: absolute;
  width: 100%;

  &--landing {
    @extend .o-background-image;
    margin-left: 50%;
    margin-top: -10%;
    transform: rotate(339deg);
  }
}

.o-background-image > img {
  background-position: center;
  width: 100%;
}

.o-fill-background-on-scroll--blue {
  background-color: $everyHourBlue;
}

.o-iframe-fullscreen-content {
  width: 100%;
  height: 500px;
  &--youtube {
    @extend .o-iframe-fullscreen-content;
  }
}

.o-card {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 32px;
  border-radius: 12px;
}

.o-select-options {
  border-radius: $smallBorderRadius;
  border: 1px solid rgb(255, 255, 255);
  padding: 4px;
  background-color: $everyHourBlue;
}

.o-input-field {
  padding: 8px;
  border-radius: $smallBorderRadius;
  border: 1px solid rgb(255, 255, 255);
}

.o-input-field--color {
  min-height: 32px;
}

.o-input-field * {
  width: 100%;
}

.o-input-field--checkbox * {
  width: auto;
}

.o-input__label {
  position: absolute;
}

.o-input-field input {
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  padding: 4px;
}

.o-button {
  color: $white;
  border-radius: $mediumBorderRadius;
  border: none;
  background-color: $yellow;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  width: max-content;
  outline: none;

  &:hover {
    background-color: $black;
  }
}

.o-button--landing {
  @extend .o-button;
  border-radius: 9px;
  padding: 12px;
  position: relative;
  background-color: $everyHourBlue;
  cursor: pointer;
}

.o-rainbow-background {
  color: $white;
  border-radius: $mediumBorderRadius;
  position: relative;
  background: linear-gradient(
    to right,
    #0f76ca,
    #15d4a8,
    #5cc922,
    #d3d926,
    #e24d2a,
    #c93bdb
  );
  padding: 3px;
}

.o-button--stretch {
  width: 100%;
}

.o-button--disabled {
  @extend .o-button;
  background-color: $light_grey;
  cursor: not-allowed;

  &:hover {
    background-color: $light_grey;
  }
}

.o-button--black-text {
  @extend .o-button;
  color: $black;
}

.o-button--purple {
  @extend .o-button;
  background-color: $purple;
}

.o-button--red {
  @extend .o-button;
  background-color: $red;
}

.o-button--green {
  @extend .o-button;
  background-color: $green;
}

.o-button--grey {
  @extend .o-button;
  background-color: $grey;
}

.o-button--wide {
  @extend .o-button;
  padding-left: 24px;
  padding-right: 24px;
}

.o-emoji-features {
  font-size: 100px;
  img {
    height: 80px;
  }
}

// animated bullet point blob
@keyframes pulse {
  0% {
    transform: scale(0.25);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.o-blob {
  background: rgb(150, 3, 187);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse 1.8s infinite;
}

@keyframes pulse-nobackground {
  0% {
    transform: scale(0.25);
  }

  70% {
    transform: scale(3);
  }

  100% {
    transform: scale(1);
  }
}

.o-celebrate-animation {
  transform: scale(1);
  animation: pulse-nobackground 1.8s;
}

/* Mobile functionality */
@include mobileOnly() {
  .o-emoji-features {
    font-size: 38px;
    img {
      height: 38px;
    }
  }

  .o-iframe-fullscreen-content--youtube {
    height: fit-content;
  }
}
