/* Margins */

.u-mauto {
  margin: auto;
}

.u-mlauto {
  margin-left: auto;
}

​.u-mrauto {
  margin-right: auto;
}

.u-mtauto {
  margin-top: auto;
}

​.u-mbauto {
  margin-bottom: auto;
}

.u-m0auto {
  margin: 0 auto;
}

.u-m--4 {
  margin: 4px;
}

.u-m--8 {
  margin: 8px;
}

.u-m--12 {
  margin: 12px;
}

.u-m--16 {
  margin: 16px;
}

.u-m--20 {
  margin: 20px;
}

.u-m--24 {
  margin: 24px;
}

.u-m--32 {
  margin: 32px;
}

.u-m--50 {
  margin: 50px;
}

.u-m--90 {
  margin: 90px;
}

.u-m--120 {
  margin: 120px;
}

.u-mv--4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.u-mv--8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.u-mv--12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.u-mv--24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.u-mv--32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.u-mv--48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.u-mv--80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.u-mv--100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.u-mv--120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.u-mv--140 {
  margin-top: 140px;
  margin-bottom: 140px;
}

.u-mv--280 {
  margin-top: 280px;
  margin-bottom: 280px;
}

.u-mh--4 {
  margin-left: 4px;
  margin-right: 4px;
}
.u-mh--8 {
  margin-left: 8px;
  margin-right: 8px;
}

.u-mh--12 {
  margin-left: 12px;
  margin-right: 12px;
}

.u-mh--16 {
  margin-left: 16px;
  margin-right: 16px;
}

.u-mh--32 {
  margin-left: 32px;
  margin-right: 32px;
}

.u-mh--120 {
  margin-left: 120px;
  margin-right: 120px;
}

.u-mt--minus28 {
  margin-top: -28px;
}

.u-mt--8 {
  margin-top: 8px;
}

.u-mt--16 {
  margin-top: 16px;
}

.u-mt--24 {
  margin-top: 24px;
}

.u-mt--32 {
  margin-top: 32px;
}

.u-mt--48 {
  margin-top: 48px;
}

.u-mt--64 {
  margin-top: 64px;
}

.u-mt--80 {
  margin-top: 80px;
}

.u-mt--120 {
  margin-top: 120px;
}

.u-mb--8 {
  margin-bottom: 8px;
}

.u-mb--16 {
  margin-bottom: 16px;
}

.u-mb--24 {
  margin-bottom: 24px;
}

.u-mb--32 {
  margin-bottom: 32px;
}

.u-mb--48 {
  margin-bottom: 48px;
}

.u-mb--64 {
  margin-bottom: 64px;
}

.u-mr--0 {
  margin-right: 0px;
}

.u-mr--4 {
  margin-right: 4px;
}

.u-mr--8 {
  margin-right: 8px;
}

.u-mr--12 {
  margin-right: 12px;
}

.u-mr--16 {
  margin-right: 16px;
}

.u-mr--24 {
  margin-right: 16px;
}

.u-ml--4 {
  margin-left: 4px;
}

.u-ml--8 {
  margin-left: 8px;
}

.u-ml--16 {
  margin-left: 16px;
}

.u-ml--24 {
  margin-left: 16px;
}

/* Padding */

.u-p--1 {
  padding: 1px;
}

.u-p--8 {
  padding: 8px;
}

.u-p--12 {
  padding: 12px;
}

.u-p--16 {
  padding: 16px;
}

.u-p--24 {
  padding: 24px;
}

.u-p--32 {
  padding: 32px;
}

.u-pt--4 {
  padding-top: 4px;
}

.u-pt--12 {
  padding-top: 12px;
}

.u-pt--16 {
  padding-top: 16px;
}

.u-pt--32 {
  padding-top: 32px;
}

.u-pt--100 {
  padding-top: 100px;
}

.u-pb--8 {
  padding-bottom: 8px;
}

.u-pb--16 {
  padding-bottom: 16px;
}

.u-pb--48 {
  padding-bottom: 48px;
}

.u-pb--64 {
  padding-bottom: 64px;
}

.u-pl--48 {
  padding-left: 48px;
}

.u-pr--16 {
  padding-right: 16px;
}

.u-pr--12 {
  padding-right: 12px;
}

.u-pr--32 {
  padding-right: 32px;
}

.u-pr--48 {
  padding-right: 48px;
}

.u-p--48 {
  padding: 48px;
}

.u-pv--8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.u-pv--12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.u-pv--16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.u-pv--24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.u-pv--32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.u-ph--8 {
  padding-left: 8px;
  padding-right: 8px;
}

.u-ph--12 {
  padding-left: 12px;
  padding-right: 12px;
}

.u-ph--16 {
  padding-left: 16px;
  padding-right: 16px;
}

.u-ph--24 {
  padding-left: 24px;
  padding-right: 24px;
}

.u-ph--32 {
  padding-left: 32px;
  padding-right: 32px;
}

.u-ph--100 {
  padding-left: 100px;
  padding-right: 100px;
}

.u-stacked-on-mobile-split-screen-on-desktop {
  display: flex;
  > div {
    width: 50%;
  }
}

// Mobile spacing

@include mobileOnly() {
  .u-stacked-on-mobile-split-screen-on-desktop {
    display: inline;
    > div {
      width: 100%;
    }
  }

  .u-mt--12--mobile {
    margin-top: 12px;
  }

  .u-mt--25--mobile {
    margin-top: 25px;
  }

  .u-p--0--mobile {
    padding: 0px;
  }

  .u-p--4--mobile {
    padding: 4px;
  }

  .u-p--8--mobile {
    padding: 8px;
  }

  .u-p--12--mobile {
    padding: 12px;
  }

  .u-p--16--mobile {
    padding: 16px;
  }

  .u-pr--12--mobile {
    padding-right: 12px;
  }

  .u-pv--8--mobile {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .u-m--24--mobile {
    margin: 24px;
  }

  .u-m--4--mobile {
    margin: 4px;
  }

  .u-mb--4--mobile {
    margin-bottom: 4px;
  }

  .u-mr--4--mobile {
    margin-right: 4px;
  }

  .u-ml--4--mobile {
    margin-left: 4px;
  }

  .u-mb--4--mobile {
    margin-bottom: 4px;
  }

  .u-mt--16--mobile {
    margin-top: 16px;
  }

  .u-mb--12--mobile {
    margin-bottom: 12px;
  }

  .u-mb--16--mobile {
    margin-bottom: 16px;
  }

  .u-mb--24--mobile {
    margin-bottom: 24px;
  }

  .u-mb--48--mobile {
    margin-bottom: 48px;
  }

  .u-mt--48--mobile {
    margin-top: 48px;
  }

  .u-mt--32--mobile {
    margin-top: 32px;
  }

  .u-mt--64--mobile {
    margin-top: 64px;
  }

  .u-mv--24--mobile {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .u-mv--64--mobile {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .u-mv--120--mobile {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}

@include desktopOnly {
  .u-ph--20--desktop {
    padding-left: 20px;
    padding-right: 20px;
  }

  .u-ph--32--desktop {
    padding-left: 32px;
    padding-right: 32px;
  }

  .u-mh--8--desktop {
    margin-left: 8px;
    margin-right: 8px120px;
  }

  .u-mh--120--desktop {
    margin-left: 120px;
    margin-right: 120px;
  }

  .u-mt--32--desktop {
    margin-top: 32px;
  }

  .u-mt--64--desktop {
    margin-top: 64px;
  }
}
