$navHeightMobile: 48px;
$navHeight: 68px;
$navLogoHeight: 48px;
$navLogoHeightMobile: 24px;

.c-navbar {
  height: $navHeight;
  top: 0;
}

.c-navbar__logo {
  height: $navLogoHeight;
}

.c-navbar__logo a {
  height: inherit;
}

.c-navbar__logo img {
  height: 100%;
}

/* Mobile fucntionality */
@include mobileOnly() {
  .c-navbar__logo {
    height: $navLogoHeightMobile;
  }

  .c-navbar {
    height: $navHeightMobile;
  }
}
