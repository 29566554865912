.u-blog-text {
  max-width: 800px;
}

.u-blog-text p {
  margin-top: 32px;
}
.u-blog-text h3 {
  font-weight: 800;
}

.o-suggested-article-card {
  background-color: $everyHourBlue;
  border-radius: 0 0 $largeBorderRadius $largeBorderRadius;
  border: solid 1px white;
  height: 300px;
  width: 100%;
}

.o-suggested-article-card--img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 150px;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.o-banner-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 300px;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.o-banner-image--inset {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 240px;
  border-radius: 32px;
}

/* Mobile functionality */
@include mobileOnly() {
  .o-banner-image {
    height: 175px;
  }

  .o-suggested-article-card--img {
    height: 175px;
  }
}
