// Fonts
$font_stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font: 400 100% /1.375 $font_stack;
$font_stack_console: "Monaco", "Menlo", "Ubuntu Mono", "Consolas",
  "source-code-pro", monospace;

// Colours
$everyHourBlue: #040821;
$red: #ec5220;
$yellow: #f4cd34;
$green: #3e9d43;
$purple: #7f398f;

// these are identical but waiting for refactor
$tomato: #ec5220;
$mustard: #f4cd34;
$grass: #3e9d43;
$taro: #7f398f;

$white: #ffffff;
$almost_white: #f1f1f1;
$light_grey: #cccccc;
$grey: #777777;
$black: #000000;

$translucent_black: rgba(0, 0, 0, 0.8);
$very_translucent_black: rgba(0, 0, 0, 0.3);
$super_translucent_black: rgba(0, 0, 0, 0.1);

// Breakpoints
$tablet-size: 45em; // 720px
$desktop-size: 60em; // 960px

$tablet-lower-bound: $tablet-size;
$desktop-lower-bound: $desktop-size;
$mobile-upper-bound: ($tablet-size - 0.0625em);
$tablet-upper-bound: ($desktop-size - 0.0625em);

// Other shit
$smallBorderRadius: 8px;
$mediumBorderRadius: 12px;
$largeBorderRadius: 32px;

// Component specific
$potHeight: 150px;
$mobilePotHeight: 75px;
$timeDrawerHeight: 128px;
$headerHeight: 142px;

// Animations
$transitionTime: 200ms;
