.block__div:not(:last-child) {
  padding-right: 12px;
}

.block__card {
  position: relative;
  background-color: $white;
  height: 40px;
  border-radius: $smallBorderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Patua One", sans-serif;
  // hack so drag and drop works on iOS safari
  transform: rotate(0deg);
  -webkit-transform: translate3d(0, 0, 0);
  // large so it is infront the hours in progress bar
  z-index: 50;
}

/* Mobile functionality */

@include mobileOnly() {
  .block__div {
    padding-right: 4px;
    padding: 0;
  }
}
