.c-settings {
  display: flex;
}

.c-settings__contents {
  position: fixed;
  width: 20%;
}

.c-settings__contents__items {
  width: 100%;
  li {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.c-settings__body {
  width: 80%;
  margin-right: 0px;
  margin-left: auto;
}

@include mobileOnly() {
  .c-settings {
    grid-template-columns: 1fr;
  }

  .c-settings__body {
    width: 100%;
    padding: 12px;
  }
}
