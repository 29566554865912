.o-line-chart__buttons-group__pots {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.o-analytics-key-highlights {
  border-radius: 20px;
  border: 1px solid #fff;
  padding: 16px;
  margin: 8px;
  flex-direction: column;
}
