@mixin mobileOnly() {
  @media screen and (max-width: $mobile-upper-bound) {
    @content;
  }
}

@mixin mobileAndTabletOnly() {
  @media screen and (max-width: $tablet-upper-bound) {
    @content;
  }
}

@mixin tabletOnly() {
  @media screen and (min-width: $tablet-lower-bound) and (max-width: $tablet-upper-bound) {
    @content;
  }
}

@mixin tabletAndDesktopOnly() {
  @media screen and (min-width: $tablet-lower-bound) {
    @content;
  }
}

@mixin desktopOnly() {
  @media screen and (min-width: $desktop-lower-bound) {
    @content;
  }
}
