.submit-pots-drawer {
  overflow: hidden; // Hide scrollbars
  padding-bottom: 32px; // this must be 28 or higher to stop the back/home/apps buttons bar appearing and cancelling the drag event
  padding-top: 28px;
  margin: 0 auto;
  //not all browsers support stretching the div to fill what is available
  width: -webkit-fill-available;
  width: -moz-available;
}

.submit-pots-drawer > * {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}
