//From https://www.w3schools.com/howto/howto_css_switch.asp

.o-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.o-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.o-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.o-switch__slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.o-switch input:checked + .o-switch__slider {
  background-color: $grass;
}

.o-switch input:focus + .o-switch__slider {
  box-shadow: 0 0 1px $grass;
}

.o-switch input:checked + .o-switch__slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.o-switch__slider.round {
  border-radius: 34px;
  border: solid 1px white;
}

.o-switch__slider.round:before {
  border-radius: 50%;
}
