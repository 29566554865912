.o-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: $transitionTime all;
  // so that it is always above the other elements on the page
  z-index: 55;
  // hack so drag and drop works on iOS safari
  transform: rotate(0deg);
  -webkit-transform: translate3d(0, 0, 0);

  &--content-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__background {
    background-color: $translucent_black;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    position: absolute;
  }

  &__container {
    z-index: 2;
    position: relative;
  }

  &__content {
    position: relative;
    background-color: $everyHourBlue;
    overflow: scroll;
    width: 75vw;
    max-width: 750px;
    border-radius: 32px;
    padding: 32px;
  }
}

.o-modal .o-icon {
  fill: $white;
}

/* Mobile functionality */
@include mobileOnly() {
  .o-modal__container {
    // make sure the modal sits in the right position
    top: 0;
    left: 0;
    padding: 20px;
  }

  .o-modal__content {
    width: 95vw;
  }
}
