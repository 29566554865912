.o-error-modal {
  padding: 16px 32px;
  background-color: $red;

  &-container {
    position: fixed;
    width: 100%;
    top: 0;
  }
}
