.c-add-new-pot__button {
  border-radius: $largeBorderRadius;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: $potHeight;
  border: 1px solid $white;

  &:hover {
    border: 3px solid $white;
  }
}

@include mobileOnly() {
  .c-add-new-pot__button {
    min-height: $mobilePotHeight;
    box-shadow: 8px 8px 0px $black;
    border-radius: $mediumBorderRadius;
  }
}
