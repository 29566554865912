.o-hoverover-overlay {
  background-color: #ffffff;
  opacity: 0;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: -1;

  // it is over 48 as must be visible over all the possible half hours in a day
  // hack so drag and drop works on iOS safari
  transform: rotate(0deg);
  -webkit-transform: translate3d(0, 0, 0);
}

.o-hoverover-element:hover .o-hoverover-overlay {
  transition: opacity 0.25s;
  opacity: 1;
  z-index: 100; //needs to be over 96
}

.o-hoverover-overlay--target {
  opacity: 0;
  width: inherit;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}

.o-hoverover-element:hover .o-hoverover-overlay--target {
  transition: opacity 0.7s, margin-top 0.7s;
  opacity: 1;
  z-index: 100; //needs to be over 96
  margin-top: 0px;
}
